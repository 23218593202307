import 'dayjs/locale/zh-cn';
import 'nprogress/nprogress.css';
import '@icon-park/react/styles/index.less';
import NProgress from 'nprogress';
import locale from 'antd/locale/zh_CN';
import React, { Suspense, lazy, ReactNode, useEffect } from 'react';
import { IconProvider, DEFAULT_ICON_CONFIGS } from '@icon-park/react'
import './App.css';
import type { FC } from 'react';
import { BrowserRouter, Routes, Route, Link, HashRouter, useNavigate } from 'react-router-dom';
import KeepAlive, { AliveScope, useAliveController } from 'react-activation';
import 'antd/dist/reset.css';
import './assets/style/com.scss';
import { Provider } from "react-redux";
import store from './redux/store';
import { ConfigProvider, message } from 'antd';
import { setNavigate } from './redux/navigate.slice';
const Login = lazy(() => import('./views/login/Login'));
const MainLayout = lazy(() => import('./layout/MainLayout'));
const UserManage = lazy(() => import('./views/system-manage/user-manage/UserManage'));
const DeptManage = lazy(() => import('./views/system-manage/dept-manage/DeptManage'));
const RoleManage = lazy(() => import('./views/system-manage/role-manage/RoleManage'));
const FunctionManage = lazy(() => import('./views/system-manage/function-manage/FunctionManage'));
const ScopeManage = lazy(() => import('./views/system-manage/scope-manage/ScopeManage'));
const TopicManage = lazy(() => import('./views/data-manage/topic-manage/TopicManage'));
const TopicValueManage = lazy(() => import('./views/data-manage/topic-value-manage/TopicValueManage'));
const TopicTemplateManage = lazy(() => import('./views/data-manage/topic-template-manage/TopicTemplateManage'));
const ProtectTypeManage = lazy(() => import('./views/data-manage/protect-type-manage/ProtectTypeManage'));
const SpeciesBaseManage = lazy(() => import('./views/base-data-manage/species-base-manage/SpeciesBaseManage'));
const SpeciesTypeManage = lazy(() => import('./views/base-data-manage/species-type-manage/SpeciesTypeManage'));
const MapLayerManage = lazy(() => import('./views/map-manage/map-layer-manage/MapLayerManage'));
const GroupManage = lazy(() => import('./views/group-manage/group-manage/GroupManage'));
const GroupDataManage = lazy(() => import('./views/group-manage/group-data-manage/GroupDataManage'));
const GroupUserManage = lazy(() => import('./views/group-manage/group-user-manage/GroupUserManage'));
const LoginLog = lazy(() => import('./views/system-monitor/login-log/LoginLog'));
const OptionLog = lazy(() => import('./views/system-monitor/option-log/OptionLog'));
const AbnormalAlarm = lazy(() => import('./views/system-monitor/abnormal-alarm/AbnormalAlarm'));
const FeedBack = lazy(() => import('./views/system-monitor/feed-back/FeedBack'));
const DataKanban = lazy(() => import('./views/system-monitor/data-kanban/DataKanban'));
const PanningBasisManage = lazy(() => import('./views/data-manage/panning-basis-manage/PanningBasisManage'));
const PaidPackageManage = lazy(() => import('./views/website-manage/paid-package-manage/PaidPackageManage'))
const AgreementManage = lazy(() => import('./views/system-manage/agreement-manage/AgreementManage'));

const App: FC = () => {

  useEffect(() => {
    message.config({
      top: 21,
    });
    return () => {

    }
  })

  const LoadingProgress: FC = () => {
    useEffect(() => {
      NProgress.start();
      return () => {
        NProgress.done();
      }
    })
    return (
      <div></div>
    )
  };

  const lazyLoadRouteEl = (chilren: ReactNode) => {
    return (<Suspense
      fallback={
        <LoadingProgress />
      }>
      {chilren}
    </Suspense>)
  }

  const IconConfig = { ...DEFAULT_ICON_CONFIGS, prefix: 'icon' }

  return (
    <div className="App app-outer-container">
      <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#337DE5',
            },
          }}
          locale={locale}
        >
          <IconProvider value={IconConfig}>
            <BrowserRouter>
              <AliveScope>
                <Routes>
                  <Route path='/' element={(<MainLayout />)}>
                    <Route path="user-manage" element={lazyLoadRouteEl(<UserManage />)} />
                    <Route path="dept-manage" element={lazyLoadRouteEl(<DeptManage />)} />
                    <Route path="role-manage" element={lazyLoadRouteEl(<RoleManage />)} />
                    <Route path="function-manage" element={lazyLoadRouteEl(<FunctionManage />)} />
                    <Route path="scope-manage" element={lazyLoadRouteEl(<ScopeManage />)} />
                    <Route path="topic-manage" element={(
                      <KeepAlive autoFreeze={false} cacheKey='TopicManage'>
                        {lazyLoadRouteEl(<TopicManage />)}
                      </KeepAlive>
                    )}
                    />
                    <Route path="topic-value-manage" element={(
                      <KeepAlive autoFreeze={false} cacheKey='TopicValueManage'>
                        {lazyLoadRouteEl(<TopicValueManage />)}
                      </KeepAlive>
                    )} />
                    <Route path="project-template-manage" element={(
                      <KeepAlive autoFreeze={false} cacheKey='TopicTemplateManage'>
                        {lazyLoadRouteEl(<TopicTemplateManage />)}
                      </KeepAlive>
                    )} />
                    <Route path="protect-type-manage" element={lazyLoadRouteEl(<ProtectTypeManage />)} />
                    <Route path="species-base-manage" element={lazyLoadRouteEl(<SpeciesBaseManage />)} />
                    <Route path="species-type-manage" element={lazyLoadRouteEl(<SpeciesTypeManage />)} />
                    <Route path="map-layer-manage" element={(
                      <KeepAlive autoFreeze={false} cacheKey='MapLayerManage'>
                        {lazyLoadRouteEl(<MapLayerManage />)}
                      </KeepAlive>
                    )} />
                    <Route path="agreement-manage" element={lazyLoadRouteEl(<AgreementManage/>)}></Route>
                    <Route path="group-manage" element={lazyLoadRouteEl(<GroupManage />)} />
                    <Route path="group-data-manage" element={lazyLoadRouteEl(<GroupDataManage />)} />
                    <Route path="group-user-manage" element={lazyLoadRouteEl(<GroupUserManage />)} />
                    <Route path="login-log" element={lazyLoadRouteEl(<LoginLog />)} />
                    <Route path="option-log" element={lazyLoadRouteEl(<OptionLog />)} />
                    <Route path="abnormal-alarm" element={lazyLoadRouteEl(<AbnormalAlarm />)} />
                    <Route path="feed-back" element={lazyLoadRouteEl(<FeedBack />)} />
                    <Route path="dashboard" element={lazyLoadRouteEl(<DataKanban />)} />
                    <Route path="panning-basis-manage" element={lazyLoadRouteEl(<PanningBasisManage />)} />
                    <Route path='paid-package-manage' element={lazyLoadRouteEl(<PaidPackageManage />)} />
                  </Route>
                  <Route path="/login" element={lazyLoadRouteEl(<Login />)} />
                </Routes>
              </AliveScope>
            </BrowserRouter>
          </IconProvider>
        </ConfigProvider>
      </Provider>
    </div>
  );
}

export default App;

